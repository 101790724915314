













import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Hints } from "@/store/tracker_important/state";
import { formatItem } from "@/util";

const tracker = namespace("tracker_important");

@Component
export default class Reports extends Vue {
  @tracker.State hints!: Hints;
  @tracker.State("currentLocation") location!: string;

  incorrectReports: number[] = [];
  msg = "";
  hintsAtBottom: boolean = this.$store.state.settings.important.hintsAtBottom;
  timer = -1;

  checkReport(i: number): void {
    const hint = this.hints[i - 1];
    if (hint.report !== this.location) {
      this.incorrectReports.push(i);
      this.$store.commit("tracker_important/incrementIncorrectReport", i - 1);
      return;
    }

    const msg
      = formatItem(hint.location)
      + (hint.checks === 0
        ? " is a heartless choice"
        : ` has ${hint.checks} important check${hint.checks !== 1 ? "s" : ""}`);

    console.log(msg);

    this.$store.dispatch("tracker_important/foundCheck", {
      check: "other/secret_reports",
      location: this.location,
    });
    this.$store.dispatch("tracker_important/foundHint", i - 1);

    if (this.hintsAtBottom) {
      this.$store.commit("tracker_important/setHintMessage", msg);
      this.$router.push("/");
    } else {
      this.msg = msg;
      this.timer = window.setTimeout(
        () => this.$router.push("/"),
        this.$store.state.settings.important.autoHideHintsDelay ?? 3000,
      );
    }
  }

  destroyed() {
    if (this.timer !== -1) {
      window.clearTimeout(this.timer);
    }
  }
}
