































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import BaseTooltip from "@/components/menu/BaseTooltip.vue";
import SwitchSlider from "@/components/menu/SwitchSlider.vue";
import { Game } from "@/store/settings";

const tracker = namespace("tracker_important");

@Component({
  components: {
    BaseTooltip,
    SwitchSlider,
  },
})
export default class TheFooter extends Vue {
  @tracker.State hintsLoaded!: boolean;

  routes: Array<string> = ["info", "co-op", "settings"];
  // eslint-disable-next-line no-undef
  version = process.env.PACKAGE_VER;

  title(route: string): string {
    return route[0].toUpperCase() + route.slice(1);
  }

  get Game() {
    return Game;
  }

  get fileElem(): HTMLInputElement {
    return this.$refs.file as HTMLInputElement;
  }

  get importantMode(): boolean {
    return this.$store.state.settings.importantChecksMode;
  }

  set importantMode(toggled: boolean) {
    this.$store.commit("settings/setImportantChecksMode", toggled);
  }

  get kh1fmMode(): boolean {
    return this.$store.state.settings.kh1fmMode;
  }

  set kh1fmMode(toggled: boolean) {
    this.$store.commit("settings/setKh1fmMode", toggled);
  }

  get game(): Game {
    return this.$store.state.settings.game;
  }

  set game(game: Game) {
    this.$store.dispatch("settings/setGame", game);
  }

  selectHints() {
    this.fileElem.click();
  }

  loadHints() {
    const files = this.fileElem.files;
    if (files === null || files.length === 0) return;

    this.$store.dispatch("tracker_important/loadHints", files[0]);
  }

  popout(): void {
    window.open("#/", "", "left=0,top=0,width=600,height=1200,menubar=no,toolbar=no,scrollbars=no");
  }

  hideFooter(): void {
    this.$store.commit("toggleFooter");
  }

  reset(): void {
    this.$store.dispatch("reset");
  }
}
