











import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import semver from "semver";

const BaseGrid = () => import("./components/tracker/normal/BaseGrid.vue");
const ImportantGrid = () => import("./components/tracker/important/ImportantGrid.vue");
import TheFooter from "./components/TheFooter.vue";
import ModalView from "./components/menu/ModalView.vue";
import { Game } from "@/store/settings";

@Component({
  components: {
    BaseGrid,
    TheFooter,
    ModalView,
    ImportantGrid,
  },
})
export default class extends Vue {
  @Watch("$route", { immediate: true })
  onRouteChange(to: Route, _from: Route): void {
    if (to.meta.title) document.title = `${to.meta.title} | KH Item Tracker`;
    else document.title = "KH Item Tracker";
  }

  async created() {
    document.body.onmousedown = (event: MouseEvent) => {
      if (event.button === 1)
        // Prevent autoscroll on middle click
        return false;
    };

    if (!this.$store.state.settings.autosave) {
      this.$store.dispatch("tracker/resetState");
      this.$store.commit("tracker_important/resetState");
    }

    let version = this.$store.state.version;
    if (semver.valid(version) && semver.lt(version, "2.10.0")) {
      const { migrate } = await import("./migrations/2.10.0");
      await migrate(this.$store);
    }

    if (this.$route.query.footer === "0") {
      this.$store.commit("toggleFooter");
    }

    version = this.$store.state.version;
    if (version && version !== this.$store.state.currVersion) {
      this.$router.push("changelog");
    }

    this.$store.commit("updateVersion");

    if (
      this.$store.state.settings.game !== Game.KH2_IC
      && this.$store.getters["tracker/items"]("self") === undefined
    ) {
      await this.$store.dispatch("tracker/addClient", "self");
    }
  }

  get route(): Route {
    return this.$route;
  }

  get Game() {
    return Game;
  }

  get mainStyle(): object {
    if (!this.footer) {
      return {
        display: "flex",
        "align-items": "center",
        flex: 1,
      };
    }

    return {};
  }

  get importantMode(): boolean {
    return this.$store.state.settings.importantChecksMode;
  }

  get game(): Game {
    return this.$store.state.settings.game;
  }

  get clients(): object {
    const clients = this.$store.state.tracker;

    return this.$store.state.co_op.single ? { self: clients.self } : clients;
  }

  get footer(): boolean {
    return this.$store.state.footer;
  }

  handleClick(): void {
    if (!this.importantMode) {
      return;
    }

    this.$store.commit("tracker_important/selectLocation", "Free");
  }
}
