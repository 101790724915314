







import { Component, Vue } from "vue-property-decorator";

@Component
export default class ModalView extends Vue {
  get show(): boolean {
    return this.$route.path !== "/";
  }

  gotoRoot(): void {
    this.$router.push("/");
  }

  mounted(): void {
    window.addEventListener("keydown", e => {
      if (e.key === "Escape" && this.show) {
        this.gotoRoot();
      }
    });
  }
}
