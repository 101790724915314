






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SwitchSlider extends Vue {
  @Prop(Boolean) value!: boolean;
}
